<template>
  <b-modal id="vacation_modal" modal-class="vacation-modal" hide-footer>
    <template #modal-title>
      <p class="vacation-modal__name mb-5">
        <span>
          <strong class="mr-2">{{ form.talent ? form.talent.first_name : "" }}</strong>
          <strong>{{ form.talent ? form.talent.last_name : "" }}</strong>
        </span>
      </p>
      <span class="d-block m-fs-12 text-primary mb-3 vacation-modal__title">Start Date</span>
      <span class="d-block m-fs-14 text-dark-blue mb-4 vacation-modal__date">{{
        form.start_date
      }}</span>
      <span class="d-block m-fs-12 text-primary mb-3 vacation-modal__title">Resumption Date</span>
      <span class="d-block m-fs-14 text-dark-blue mb-4 vacation-modal__date">{{
        form.end_date
      }}</span>
    </template>
    <span class="text-primary m-fs-12 d-block mb-2 vacation-modal__title">Reason for leave</span>
    <p class="text-dark-blue vacation-modal__reason">
      {{ form.reason }}
    </p>
  </b-modal>
</template>
  <script>
export default {
  name: "VacationModal",
  props: {
    vacation: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: this.vacation,
    };
  },

  watch: {
    vacation(newVacation, oldVacation) {
      this.form = newVacation;
    },
  },
};
</script>
  <style lang="scss" scoped>
.vacation-modal {
  &__date {
    color: $dark-blue;
    font-size: 0.875rem;
  }

  &__name {
    color: $dark-blue;
    font-weight: 700;
    font-size: 0.875rem;
  }

  &__title {
    color: $faint-blue;
    font-weight: 400;
    font-size: 0.75rem;
    margin-bottom: 0.3rem;
  }

  &__reason {
    color: $dark-blue;
    font-size: 0.875rem;
  }
}
</style>