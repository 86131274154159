<template>
  <div class="dashboard-content py-5">
    <div class="d-flex justify-content-end pb-4 pt-5">
      <div class="m-width-300 mr-2">
        <SearchBox
          v-model="searchVacations"
          bg-color="#D6DDE4"
          :rounded="5"
          placeholder="Search users by name, type..."
        />
      </div>

      <!-- <div>
        <FilterDropdown variant="faint-blue" :filters="filters" @filtered="setFilter" />
      </div> -->
    </div>

    <div class="vacation-table m-rounded-10 mb-5 py-2">
      <b-table
        responsive
        striped
        borderless
        :fields="fields"
        :items="filteredVacations"
      >
        <template #cell(action)="data">
          <OptionsDropdown
            :toggle-class="['text-decoration-none']"
            :options-data="optionsData"
            :options-id="data.item.id"
            :onClickOption="(type) => handleClick(type, data.item.id)"
          />
        </template>
        <template #cell(status)="data">
          <span :class="getVariant(data.item.status)">
            {{ data.item.status }}
          </span>
        </template>
      </b-table>

      <div
        class="m-pagination overflow-auto d-flex p-4 justify-cotnent-between align-items-center"
      >
        <span class="flex-grow-1">
          Showing page {{ currentPage }} of {{ totalRows }}
        </span>
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          align="right"
          pills
        >
        </b-pagination>
      </div>
    </div>
    <VacationModal id="vacation_modal" :vacation="vacation" />
  </div>
</template>

<script>
import SearchBox from "../../components/SearchBox.vue";
import OptionsDropdown from "../../components/OptionsDropdown.vue";
import VacationModal from "../../components/vacation/VacationModal.vue";
import { mapGetters, mapActions } from "vuex";
// import FilterDropdown from "../../components/FilterDropdown.vue";

export default {
  components: {
    SearchBox,
    OptionsDropdown,
    VacationModal,
    // FilterDropdown,
  },

  data() {
    return {
      searchVacations: "",
      filterValue: "weekly",
      fields: [
        {
          key: "talent.name",
          label: "Name of Employee"
        },
        {
          key: "reason",
          label: "Reason For Leave"
        },
        {
          key: "start_date",
          label: "Start Date"
        },
        {
          key: "end_date",
          label: "Resumption Date"
        },
        {
          key: "working_days",
          label: "Working Days"
        },
        {
          key: "status_readable",
          label: "Status"
        },
        {
          key: "action",
        },
      ],
      borderless: true,
      currentPage: 1,
      perPage: 7,
      statuses: {
        Approved: "text-success",
        Pending: "text-warning",
      },
      filters: [
        {
          name: "Today",
          value: "today",
        },
        {
          name: "Weekly",
          value: "weekly",
        },
        {
          name: "Monthly",
          value: "monthly",
        },
      ],
      items: [
        {
          name: "Will Blake",
          reason_for_leave: "Srspiciatis",
          start_date: "30/10/21",
          resumption_date: "30/10/21",
          working_days: 4,
          status: "Approved",
          action: "",
        },
        {
          name: "Will Blake",
          reason_for_leave: "Srspiciatis",
          start_date: "30/10/21",
          resumption_date: "30/10/21",
          working_days: 3,
          status: "Pending",
          action: "",
        },
        {
          name: "Will Blake",
          reason_for_leave: "Srspiciatis",
          start_date: "30/10/21",
          resumption_date: "30/10/21",
          working_days: 4,
          status: "Approved",
          action: "",
        },
        {
          name: "Will Blake",
          reason_for_leave: "Srspiciatis",
          start_date: "30/10/21",
          resumption_date: "30/10/21",
          working_days: 3,
          status: "Pending",
          action: "",
        },
        {
          name: "Will Blake",
          reason_for_leave: "Srspiciatis",
          start_date: "30/10/21",
          resumption_date: "30/10/21",
          working_days: 4,
          status: "Approved",
          action: "",
        },
        {
          name: "Will Blake",
          reason_for_leave: "Srspiciatis",
          start_date: "30/10/21",
          resumption_date: "30/10/21",
          working_days: 3,
          status: "Pending",
          action: "",
        },
      ],
      optionsData: [
        {
          name: "Details",
          value: "details",
          class: ["text-tertiary"],
        },
        {
          name: "Approve Vacation",
          value: "approve",
          class: ["text-success"],
        },
        {
          name: "Decline Vacation",
          value: "decline",
          class: ["text-secondary"],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["vacations", "vacation"]),
    totalRows() {
      return this.vacations.length;
    },
    filteredVacations() {
      return this.vacations.filter((vacation) => {
        return vacation.reason.toLowerCase().match(this.searchVacations.toLowerCase()) ||
               vacation.start_date.match(this.searchVacations) ||
               vacation.end_date.match(this.searchVacations) ||
               vacation.talent.name.toLowerCase().match(this.searchVacations.toLowerCase())
      })
    }
  },

  created() {
    this.fetchVacations()
    console.log(this.$store) 
  }, 

  methods: {
    ...mapActions([
      "fetchVacations", 
      "getVacation", 
      "approveVacation", 
      "declineVacation"
    ]),
    getVariant(status) {
      return this.statuses[status];
    },
    setFilter(value) {
      console.log(value);
    },
    handleClick(type, id) {
      if (type === "details") {
        console.log("working")
        this.getVacation(id);
        this.$bvModal.show('vacation_modal');
      }

      if (type === "approve") {
        this.getVacation(id);
        this.$store
        .dispatch("approveVacation", {
          id: this.vacation.id,
          data: {
            status: this.vacation.status,
            status_readable: this.vacation.status_readable,
          }
        })
        .then(() => {
          this.fetchVacations()
        }) 
        // this.approveVacation(id)
      }

      if (type === "decline") {
        this.getVacation(id);
        this.$store
        .dispatch("declineVacation", {
          id: this.vacation.id,
          data: {
            status: this.vacation.status,
            status_readable: this.vacation.status_readable,
          }
        })
        .then(() => {
          this.fetchVacations()
        }) 
      }
      console.log(type, id)
      console.log(this.vacation, this.vacation.id, "working")
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.vacation-table {
  background-color: $white;
}
</style>
